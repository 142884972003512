export const experienceData = [
  {
    id: 1,
    company: "YourDworld Technology Limited",
    jobtitle: "Full Stack Developer",
    startYear: "08/2023",
    endYear: "present",
  },
  {
    id: 2,
    company: "Spacenos Technologies",
    jobtitle: "Software Developer",
    startYear: "05/2022",
    endYear: "06/2023",
  },
  {
    id: 3,
    company: "Ozyus Solutions",
    jobtitle: "Software Development Engineer",
    startYear: "05/2021",
    endYear: "04/2022",
  },
];
