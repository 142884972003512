import one from "../assets/projectimage/1.jpg";
import two from "../assets/projectimage/2.jpg";
import three from "../assets/projectimage/3.jpg";
import four from "../assets/projectimage/4.svg";
import five from "../assets/projectimage/5.jpg";
import six from "../assets/projectimage/6.jpg";
import seven from "../assets/projectimage/7.jpg";
import eight from "../assets/projectimage/8.png";
import nine from "../assets/projectimage/9.png";
import ten from "../assets/projectimage/10.jpg";
import eleven from "../assets/projectimage/11.png";
import tweleve from "../assets/projectimage/12.webp";
import thirteen from "../assets/projectimage/13.webp";

export const projectsData = [
  {
    id: 13,
    projectName: "Lucky1 Dating App",
    projectDesc:
      "Lucky1 is more than a dating app; it’s a platform for meaningful, lasting relationships. Focused on compatibility and safety, we help you find your perfect match. Join thousands of satisfied users",
    tags: ["Node.js", "MySQL", "React.js", "Bootstrap"],
    code: "https://lucky1.in/",
    demo: "https://lucky1.in/",
    image: thirteen,
  },
  {
    id: 12,
    projectName: "Groot Expance Manager",
    projectDesc:
      "Groot - Expense Manager lets you easily manage your finances with customizable accounts, cards, and categories. Track transactions, analyze data with graphs, and budget wisely. Take control of your money today!",
    tags: ["Node.js", "MySQL", "React.js", "Bootstrap"],
    code: "https://grootapp.com/",
    demo: "https://grootapp.com/",
    image: tweleve,
  },

  {
    id: 1,
    projectName: "One-Click-Site-Builder",
    projectDesc:
      "You can build Website within just One line description and also its give us to hosted site",
    tags: ["React", "Javascript", "Firebase", "Node.js"],
    code: "https://one-click-website-builder.web.app/",
    demo: "https://one-click-website-builder.web.app/",
    image: one,
  },
  {
    id: 2,
    projectName: "Dibbs-Marketplace",
    projectDesc:
      "In this plateform You can post a work for workers and also workers can get works from here",
    tags: ["React", "Javascript", "Firebase", "Node.js"],
    code: "https://app.dibbstechnology.com/",
    demo: "https://app.dibbstechnology.com/",
    image: two,
  },
  {
    id: 3,
    projectName: "Algocraze",
    projectDesc: "This Plateform about algo base trading",
    tags: ["React", "Javascript", "CSS"],
    code: "#",
    demo: "#",
    image: three,
  },
  {
    id: 4,
    projectName: "Dibbs-Backend",
    projectDesc: "In This project we have created Cloud Function in GCP",
    tags: ["Cloud Function", "Firebase", "Node.js"],
    code: "#",
    demo: "#",
    image: four,
  },
  {
    id: 5,
    projectName: "Real-Time Chat Application",
    projectDesc: "Its Real Time Chat App based on google Auth",
    tags: ["React", "Javascript", "Firebase", "Node.js"],
    code: "https://mercor-backend-task.web.app",
    demo: "https://mercor-backend-task.web.app",
    image: five,
  },
  {
    id: 6,
    projectName: "Whether Forcasting",
    projectDesc:
      "IN this project we have created whether forcasting app where You can see everyday whether",
    tags: ["React", "Javascript", "Tailwand-CSS"],
    code: "https://lucientaskwhetherapp.netlify.app/",
    demo: "https://lucientaskwhetherapp.netlify.app/",
    image: six,
  },
  {
    id: 7,
    projectName: "Selling Dashboard",
    projectDesc: "Its Online Book Store",
    tags: ["React", "Javascript", "Firebase"],
    code: "https://selling-dashboard.web.app/",
    demo: "https://selling-dashboard.web.app/",
    image: seven,
  },
  {
    id: 8,
    projectName: "Leadmato Chrome Extention",
    projectDesc:
      "Its basically Whatsapp extention to grab information from whatsapp",
    tags: ["Javascript", "HTML", "CSS"],
    code: "#",
    demo: "#",
    image: eight,
  },
  {
    id: 9,
    projectName: "The Product Study Landing Page",
    projectDesc: "Its Landing page of Product study portal",
    tags: ["HTML", "Javascript", "CSS"],
    code: "https://theproductstudy.com/",
    demo: "https://theproductstudy.com/",
    image: nine,
  },
  {
    id: 10,
    projectName: "Waitlist",
    projectDesc: "Its simpally waitlist to show a waiter list",
    tags: ["React", "Firebase", "Node.js", "AWS"],
    code: "https://waitlist-admin.web.app/",
    demo: "https://waitlist-admin.web.app/",
    image: ten,
  },
  {
    id: 11,
    projectName: "One Click site builder Landing Page",
    projectDesc: "its a landing pages of one click site builder",
    tags: ["HTML", "Javascript", "CSS"],
    code: "https://oneclickbuilder.com/",
    demo: "https://oneclickbuilder.com/",
    image: eleven,
  },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
