import Ideathon from '../assets/achivementsimage/Ideathon.png'
import Final_Result from '../assets/achivementsimage/Final_Result.png'

export const achievementData = {
    bio : "Putting quantified achievements on a resume is great, but I just don’t work with hard numbers. I have no data to support the quality of my work. If you have lots of relevant experience, the heading statement of your resume will be a summary.",
    achievements : [
        {
            id : 1,
            title : 'Winner in GitHub hackathon',
            details : 'We are The first in this Code innovation series hackathon which is organized by Github',
            date : 'Aug 20, 2019',
            field : 'Hackathon',
            image : 'https://images.unsplash.com/photo-1618401471353-b98afee0b2eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1188&q=80'
        },
        {
            id : 2,
            title : 'Runner Up in State Level Hackathon',
            details : 'We are in Runner Up in Ideathon which is organised by GEC Rajkot',
            date : 'Aug 20, 2019',
            field : 'Hackathon',
            image : Final_Result
        },
        {
            id : 3,
            title : 'Google Cloud certification',
            details : 'Google Cloud certifications validate your expertise and showcase your ability to transform businesses with Google Cloud technology.',
            date : 'Aug 20, 2019',
            field : 'Automation',
            image : 'https://images.unsplash.com/photo-1573141597928-403fcee0e056?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8Z29vZ2xlfGVufDB8fDB8fHww&auto=format&fit=crop&w=500&q=60'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/