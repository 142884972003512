import resume from "../assets/pdf/Vinay_Chauhan_Resume_F3.pdf";
import profile from "../assets/png/profile.png";
import Profile1 from "../assets/jpg/Profile Light.jpg";
import Profile2 from "../assets/jpg/Profile Dark.jpg";

export const headerData = {
  name: "Vinay Chauhan",
  title: "Full Stack Developer",
  desciption:
    "There wasn't a bird in the sky, but that was not what caught her attention. It was the clouds. The deep green that isn't the color of clouds, but came with these. he knew what was coming and she hoped she was prepared.",
  image: profile,
  resumePdf: resume,
};
