export const educationData = [
    {
        id: 1,
        institution: 'SHRI PALAV VIDHYA MANDIR | RAJKOT, INDIA',
        course: 'Primary Education',
        startYear: '2016',
        endYear: '2017'
    },
    {
        id: 2,
        institution: 'SHRI SATYAPRAKASH VIDHYAPEETH | RAJKOT, INDIA',
        course: 'Higher Education',
        startYear: '2017',
        endYear: '2019'
    },
    {
        id: 3,
        institution: 'RK UNIVERSITY | RAJKOT, INDIA',
        course: 'Bachelor of Tecnology In Computer Engineering',
        startYear: '2019',
        endYear: '2023'
    },
]