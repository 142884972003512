export const socialsData = {
  github: "https://github.com/vinay7722",
  facebook: "https://www.facebook.com/",
  linkedIn: "https://www.linkedin.com/in/vinay-chauhan7722",
  instagram: "https://www.instagram.com/vc_765",
  codepen: "https://codepen.io/",
  twitter: "https://twitter.com/",
  reddit: "https://www.reddit.com/user/",
  blogger: "https://www.blogger.com/",
  medium: "https://medium.com/@",
  stackOverflow: "https://stackoverflow.com/users/",
  gitlab: "https://gitlab.com/vc7722",
  youtube: "https://youtube.com/",
  whatsapp:
    "https://wa.me/+917265930338?text=Hello%20I%27m%20interested%20To%20Connect%20With%20you",
};
